import { navigate } from 'gatsby';
import React from 'react';
import "./marker.css"

const Marker = () => {

    const handleMapContainerClick = () => {
        navigate('https://www.google.com/maps/place/Jyothis+Special+School+and+Therapy+Centre,+Mavelikkara/@9.250762,76.5498051,15z/data=!4m5!3m4!1s0x0:0xb2aa005b0a1bbe64!8m2!3d9.250762!4d76.5498051')
    };

    return (
        <div  onClick={handleMapContainerClick} className='marker-container'>
            <div className='pin'></div>
            <div className='pulse'></div>
        </div>
    )
}

export default Marker