import { Box, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useFormControls } from "./ContactFormControls";
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Bar from "../bar"
import TextField from '@mui/material/TextField';
import { IconButton, Modal, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FeedbackForm } from "../feedback/FeedbackForm";
import { GoogleMap } from "../google-map/googlemap";
import ContactCard from "./components/contact-card/contact-card";

const inputFieldValues = [
    {
        name: "fullName",
        label: "Name *",
        id: "my-name"
    },
    {
        name: "phone",
        label: "Contact phone *",
        id: "my-name"
    },
    {
        name: "message",
        label: "Your Question/Query *",
        id: "my-message",
        multiline: true,
        rows: 7
    },
    {
        name: "whatsapp",
        label: "Whatsapp Number",
        id: "whatsapp-number"
    },
    {
        name: "email",
        label: "Email",
        id: "my-email"
    },
    {
        name: "question",
        label: "How did you come to know about us",
        id: "how-found"
    },
];

export const ContactForm = () => {
    const {
        handleInputValue,
        handleFormSubmit,
        formIsValid,
        errors,
        values
    } = useFormControls();

    const [open, setOpen] = React.useState(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setOpen(values.emailSent);
    }, [values.emailSent]);

    const handleFeedbackClick = () => {
        handleFeedbackModalOpen();
    };

    const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false);
    const handleFeedbackModalOpen = () => setOpenFeedbackModal(true);
    const handleFeedbackModalClose = () => setOpenFeedbackModal(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80vw",
        bgcolor: 'background.paper',
        p: 4,
        marginTop: 50,
        marginBottom: 50
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Grid container spacing={3} style={{ paddingTop: 30 }} alignItems="center" justifyContent="center">
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Grid container spacing={3} style={{ paddingBottom: 20 }} alignItems="center" justifyContent="center" direction="column">
                    <Typography style={{ display: "block" }} variant="h5">Contact us</Typography>
                    <Bar />
                </Grid>
            </Box>
            <Grid container spacing={3} style={{ minHeight: 500 }} alignItems="center">
                <Grid
                    item={true}
                    sm={6}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ padding: 10 }}
                >
                    <div className="map-container" >
                        <GoogleMap address={{ lat: 9.250762, lng: 76.5498051 }} googleMapsApiKey={process.env.GATSBY_GMAPS_API_KEY} />
                    </div>
                    <ContactCard />
                </Grid>
                <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                >
                    <form autoComplete="off" onSubmit={handleFormSubmit} >
                        {inputFieldValues.map((inputFieldValue, index) => {
                            return (
                                <TextField
                                    variant="outlined"
                                    style={{ padding: 8 }}
                                    key={index}
                                    onChange={handleInputValue}
                                    onBlur={handleInputValue}
                                    name={inputFieldValue.name}
                                    label={inputFieldValue.label}
                                    error={errors[inputFieldValue.name]}
                                    multiline={inputFieldValue.multiline ?? false}
                                    fullWidth
                                    minRows={inputFieldValue.rows ?? 1}
                                    autoComplete="none"
                                    {...(errors[inputFieldValue.name] && {
                                        error: true,
                                        helperText: errors[inputFieldValue.name]
                                    })}
                                />
                            );
                        })}
                        <Button
                            variant="contained"
                            type="submit"
                            color="secondary"
                            disabled={!formIsValid()}
                            style={{ marginTop: 10 }}
                        >
                            Send Message
                        </Button>
                    </form>
                </Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingBottom: 20, paddingTop: 50 }} justifyContent="center" alignItems="center" direction="column">

                <Typography
                    variant="body1"
                    className="body-font" align="center" style={{ marginBottom: 10, color: "#154b68", paddingTop: 30, paddingLeft: 10, paddingRight: 10 }}
                >
                    Your feedbacks keep us moving forward.. Kindly leave us a feedback if you visited Jyothis Special School and Therapy Centre or received our services
                </Typography>

                <Button
                    variant="contained"
                    type="submit"
                    color="secondary"
                    style={{ marginTop: 10 }}
                    onClick={handleFeedbackClick}
                >
                    Write a feedback
                </Button>

                <Modal style={{ outline: 'none', margin: 50, height: '90vh', overflow: 'scroll', overflowX: 'hidden' }}
                    sx={{ margin: { xs: "10px !important", sm: "50" } }}
                    open={openFeedbackModal}
                    onClose={handleFeedbackModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <FeedbackForm handleModalClose={handleFeedbackModalClose} />
                    </Box>
                </Modal>

            </Grid>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Thank you for contacting us! We will get back to you as soon as possible.
                Jyothis staff will contact you within the next 24-48 hours to assist. Please feel free to contact us at 0479- 2305400 OR 0479- 2306020 for immediate support"
                action={action}
            />

        </Grid>
    );
};
