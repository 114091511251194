import React from 'react';
import GoogleMapReact, { } from 'google-map-react';
import Marker from './marker/marker';

const exampleMapStyles = [
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
];

export const GoogleMap = (props) => {
    const {
        address,
        googleMapsApiKey
    } = props;
    const lat = parseFloat(address.lat);
    const lng = parseFloat(address.lng);
    return (
        <div className='map-canvas'>
            <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapsApiKey }}
                defaultCenter={address}
                defaultZoom={12}
                options={{
                    styles: exampleMapStyles,
                }}
            >
                <Marker
                    className="marker"
                    lat={lat}
                    lng={lng}
                >
                </Marker>
            </GoogleMapReact>
        </div>
    )
}