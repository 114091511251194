import { Box, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useFormControls } from "./FeedbackFormControls";
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Image from "../image"
import Hidden from "@material-ui/core/Hidden"
import Bar from "../bar"
import TextField from '@mui/material/TextField';
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const inputFieldValues = [
    {
        name: "fullName",
        label: "Name *",
        id: "my-name"
    },
    {
        name: "phone",
        label: "Contact Phone *",
        id: "my-name"
    },
    {
        name: "message",
        label: "Your Feedback *",
        id: "my-message",
        multiline: true,
        rows: 7
    },
    {
        name: "whatsapp",
        label: "Whatsapp Number",
        id: "whatsapp-number"
    },
    {
        name: "email",
        label: "Email",
        id: "my-email"
    },
    {
        name: "question",
        label: "How did you come to know about us",
        id: "how-found"
    },
];

export const FeedbackForm = ({handleModalClose}) => {
    const {
        handleInputValue,
        handleFormSubmit,
        formIsValid,
        errors,
        values
    } = useFormControls();

    const [open, setOpen] = React.useState(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setOpen(values.emailSent);
    }, [values.emailSent]);

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Grid container spacing={3} style={{ paddingBottom: 20, paddingTop: 50 }} alignItems="center" direction="column">
                <Typography style={{ display: "block" }} variant="h5">Write a feedback</Typography>
                <Bar />
            </Grid>
            <Grid container spacing={3} style={{ minHeight: 500 }} alignItems="flex-start" justifyContent="center">
                <Hidden xsDown>
                    <Grid
                        item={true}
                        sm={6}
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        style={{ padding: 10 }}
                    >
                        <div style={{ width: "90%", paddingTop: 45 }}>
                            <Image
                                alt="feedback.png"
                                filename="feedback.png"
                            />
                        </div>
                    </Grid>
                </Hidden>
                <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                >
                    <form autoComplete="off" onSubmit={handleFormSubmit} >
                        {inputFieldValues.map((inputFieldValue, index) => {
                            return (
                                <TextField
                                    variant="outlined"
                                    style={{ padding: 8 }}
                                    key={index}
                                    onChange={handleInputValue}
                                    onBlur={handleInputValue}
                                    name={inputFieldValue.name}
                                    label={inputFieldValue.label}
                                    error={errors[inputFieldValue.name]}
                                    multiline={inputFieldValue.multiline ?? false}
                                    fullWidth
                                    minRows={inputFieldValue.rows ?? 1}
                                    autoComplete="none"
                                    {...(errors[inputFieldValue.name] && {
                                        error: true,
                                        helperText: errors[inputFieldValue.name]
                                    })}
                                />
                            );
                        })}
                        <Button
                            variant="contained"
                            type="submit"
                            color="secondary"
                            disabled={!formIsValid()}
                            style={{ marginTop: 10 }}
                        >
                            Send Message
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="secondary"
                            onClick={handleModalClose}
                            style={{ marginTop: 10, marginLeft: 10, }}
                        >
                            Close
                        </Button>
                    </form>
                </Grid>
            </Grid>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Thank you for writing us a feedback! It would definitely push us forward as we strive to improve ourselves every day."
                action={action}
            />

        </div >
    );
};
